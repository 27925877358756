import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Button, Dialog, Grid, IconButton } from "@mui/material";
import { RiDeleteBin5Line } from "react-icons/ri";
import {
  CreateAppointmentApi,
  createCashInvoice,
  createInsuranceInvoice,
  createNewInvoice,
  getCashBankAccounts,
  getCompanyList,
  getCustomerList,
  getInvoiceData,
  getitemsList,
  getPatientByID,
  getPatientListDropdown,
  getPractitionersList,
  getPriceListByID,
  getslots,
  updateAppoinmentStatus,
  updateEmrItemsStatus,
  updateVisitInvoice,
} from "../components/ApiMethods";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AppLoader from "../../../components/AppLoader";
import SharedInput from "../components/SharedInput";
import SharedDropdown from "../components/SharedDropdown";
import SharedSearchDropdown from "./SharedSearchDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import priceList from "../../../gql/priceList";
import { BiSearch, BiX } from "react-icons/bi";
import _, { get, max, values } from "lodash";
import { FaArrowLeft } from "react-icons/fa";
import { items } from "../../PreAuth/components/request/BodySites";

export const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

function NewAppointment() {
  const [open, setOpen] = useState(false);
  const [payerId, setPayerID] = useState("0");
  const [payerLicense, setPayerLicense] = useState("2117");
  const formikRef = useRef(null);
  const [itemDetails, setItemDetails] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [hisRef, setRef] = useState("");
  const [insurancePlan, setInsurancePlan] = useState(null);
  const [patientType, setPatientType] = useState(null);
  const navigate = useNavigate();
  const [priceListId, setPriceListId] = useState("0");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [slotsList, setSlotList] = useState(null);
  const [cashAccount, setCashAccounts] = useState([]);
  const [patientShare, setPatientShare] = useState(null);
  const location = useLocation();
  const [selectedInsurancePlan, setSelectedInsurancePlan] = useState(null);
  const appointmentBillToPay = location?.state?.appointment;

  const {
    loading: loadingSearchPriceListCode,
    error: errorSearchPriceListCode,
    data: dataSearchPriceListCode,
    refetch: refetchSearchPriceListCode,
  } = useQuery(priceList.SEARCH_PRICE_LIST_CODE, {
    variables: {
      input: "",
      payerId: payerId,
    },
    skip: true,
  });
  const handleSearch = (term) => {
    var new_payer_id=payerId
    if(formikRef?.current?.values?.AppoinmentType==="Cash" || formikRef?.current?.values?.AppoinmentType==="cash" )
      {
        new_payer_id="2117";
      }
    refetchSearchPriceListCode({
      input: term,
      payerId: new_payer_id,
    }).then((data) => {
      setSearchResult(data?.data?.searchPriceListCode?.data || []);
    });
  };

  function changeSlotsList(list) {
    return list?.slots.map((slot, index) => ({
      value: slot,
      label: slot,
    }));
  }
  console.log(patientShare);

  const getNationality = () => {
    const patient = patientList?.data?.find((item) => item.id === hisRef);

    return patient ? patient.nationality : null;
  };
  function calculateShares(formikProps, maxLimit) {
    const items = formikProps?.values?.items || [];
    let totalPatientShare = 0;
    let totalCompanyShare = 0;
    let totalPatientTax = 0;
    let totalCompanyTax = 0;
    let totalDiscountedAmount = 0;
    const nationality = getNationality();
    const itemBreakdown = items.map((itm) => {
      const unitPrice = Number(itm?.unit_price) || 0;
      const quantity = itm?.quantity || 0;
      const discountAmount = itm?.discount_amount || 0;
      const patientSharePercentage = itm?.patient_share;

      const totalAmount = unitPrice * quantity;
      let discountedAmount;

      if (itm?.discount_type === "fixed") {
        discountedAmount = totalAmount - discountAmount;
        totalDiscountedAmount += discountAmount;
      } else {
        const discountValue = (totalAmount * discountAmount) / 100;
        totalDiscountedAmount += discountValue;
        discountedAmount = totalAmount - discountValue;
      }

      const fullPatientShare =
        (discountedAmount * patientSharePercentage) / 100;
      let currentPatientShare = 0;
      let currentCompanyShare = 0;

      if (totalPatientShare < maxLimit) {
        const remainingLimit = maxLimit - totalPatientShare;
        if (fullPatientShare <= remainingLimit) {
          currentPatientShare = fullPatientShare;
        } else {
          currentPatientShare = remainingLimit;
        }
        totalPatientShare += currentPatientShare;
      }

      currentCompanyShare = discountedAmount - currentPatientShare;
      totalCompanyShare += currentCompanyShare;

      let patientTax = 0;
      let companyTax = currentCompanyShare * 0.15;

      if ((nationality === "saudi" || nationality === "sa") && itm?.tax_check) {
        patientTax = currentPatientShare * 0.15;
      } else if (itm?.tax_check) {
        patientTax = currentPatientShare * 0.15;
      }

      totalPatientTax += patientTax;
      totalCompanyTax += companyTax;

      return {
        item_code: itm?.service_code || "",
        item_name: itm?.service_desc || "",
        item_type: itm?.service_type || "",
        discount_type: itm?.discount_type,
        unitPrice,
        quantity: Number(quantity),
        totalAmount: parseFloat(totalAmount.toFixed(2)),
        discountAmount: parseFloat(discountAmount),
        discountedAmount: parseFloat(discountedAmount.toFixed(2)),
        patientShare: parseFloat(currentPatientShare.toFixed(2)),
        companyShare: parseFloat(currentCompanyShare.toFixed(2)),
        patientTax: parseFloat(patientTax.toFixed(2)),
        companyTax: parseFloat(companyTax.toFixed(2)),
      };
    });

    return {
      itemBreakdown,
      totals: {
        patientShareTotal: parseFloat(totalPatientShare.toFixed(2)),
        companyShareTotal: parseFloat(totalCompanyShare.toFixed(2)),
        patientTaxTotal: parseFloat(totalPatientTax.toFixed(2)),
        companyTaxTotal: parseFloat(totalCompanyTax.toFixed(2)),
        discountedAmount: Number(totalDiscountedAmount),
        totalAmount:
          parseFloat(totalPatientShare.toFixed(2)) +
          parseFloat(totalCompanyShare.toFixed(2)) +
          parseFloat(totalPatientTax.toFixed(2)) +
          parseFloat(totalCompanyTax.toFixed(2)),
      },
    };
  }

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    if (newSearchTerm.length >= 3) {
      debouncedSearch(newSearchTerm);
    } else {
      setSearchResult([]);
    }
  };
  const debouncedSearch = _.debounce(handleSearch, 1000);

  const handleSelectSearchItem = (formik, item) => {
    const newItem = {
      ...item,
      quantity: 1,
      discount_type: "percentage",
      discount_amount: 5,
      tax_check: getNationality() === "saudi" ? false : true,
      ...(formik?.values?.AppoinmentType === "Insurance" && {
        patient_share: Number(selectedInsurancePlan?.patient_share),
      }),
    };
    formik.setFieldValue("items", [...formik.values.items, newItem]);

    setSearchResult([]);
    setSearchTerm("");
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const topPosition = scrollTop === 0 ? "top-10" : "top-0";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getuser = localStorage.getItem("user");
  const user = JSON.parse(getuser);

  const [loading, setLoading] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [priceListData, setPriceListData] = useState(null);
  const [paidToday, setPaidToday] = useState(true);

  const appointmentTypes = [
    { label: "Routine checkup", value: "Routine checkup" },
    { label: "Follow up", value: "Follow up" },
    { label: "Consultation", value: "Consultation" },
    { label: "Emergency", value: "Emergency" },
    { label: "Procedure", value: "Procedure" },
    { label: "Therapy", value: "Therapy" },
    { label: "Immunization", value: "Immunization" },
    { label: "Surgical", value: "Surgical" },
    { label: "Diagnostic test", value: "Diagnostic test" },
  ];

  const paymentMethods = [
    { label: "Mada", value: "Mada" },
    { label: "Cash", value: "cash" },
    { label: "Credit", value: "credit" },
    { label: "Bank", value: "bank" },
  ];

  useEffect(() => {
    getCustomerList(setLoading, setPatientList);
    getPractitionersList(setLoading, setDoctorList);
    fetchPriceList(priceListId);
    getCompanyList(setLoading, setCompanyList);
    getCashBankAccounts(setLoading, setCashAccounts);
  }, []);
  const payersData = JSON.parse(localStorage.getItem("payersData"));

  const [validate, setValidate] = useState("Cash");
  const [maxLimit, setMaxLimit] = useState(100);

  useEffect(() => {
    if (patientList?.data && appointmentBillToPay?.patient?.patient_id) {
      setRef(
        patientList.data.find(
          (patient) =>
            patient.his_refrence === appointmentBillToPay?.patient?.patient_id
        )?.id
      );
      const selectedPatient = patientList?.data?.find(
        (patient) =>
          patient.his_refrence === appointmentBillToPay?.patient?.patient_id
      );
      setPatientType(selectedPatient.beneficiary_type);
    }
  }, [patientList, appointmentBillToPay]);

  const initialValues = {
    AppoinmentType: appointmentBillToPay
      ? appointmentBillToPay?.patient?.beneficiary_type
      : "Cash",
    service_categories: appointmentBillToPay
      ? appointmentBillToPay?.service_categories
      : "",
    service_type: "",
    visit_type: appointmentBillToPay
      ? appointmentBillToPay?.visit_type?.type
      : "Consultation",
    reasons: "",
    speciality: appointmentBillToPay
      ? appointmentBillToPay?.specialty?.specialty
      : "",
    visit_date: appointmentBillToPay
      ? appointmentBillToPay?.visit_date
      : new Date().toISOString().split("T")[0],
    slots: appointmentBillToPay ? appointmentBillToPay?.start : "",

    customer_id: appointmentBillToPay
      ? appointmentBillToPay?.patient?.patient_id
      : "",
    practitioner_id: appointmentBillToPay
      ? appointmentBillToPay?.practitioner_id
      : "",
    comment: appointmentBillToPay ? appointmentBillToPay?.comment : "",
    // date: new Date().toISOString().split("T")[0],
    date: getCurrentDateTime(),
    payment_method: appointmentBillToPay?.payment_method
      ? appointmentBillToPay?.payment_method
      : "cash",
    description: "",
    paid: false,
    branch_id: 1,
    business_id: 1,
    invoice_type: "sales",
    items:
      appointmentBillToPay?.items?.filter(
        (item) => item?.status === "UnPaid"
      ) || [],
    company_id: payerId,
  };

  useEffect(() => {
    if (validate === "Cash") {
      formikRef?.current?.setFieldValue("paid", true);
    } else {
      formikRef?.current?.setFieldValue("paid", false);
    }
  }, [validate]);
  const validationSchema = yup.object().shape({
    AppoinmentType: paidToday
      ? yup.string().required("Appointment Type is required")
      : null,
    visit_type: yup.string().required("Appointment Type is required"),
    speciality: yup.string().required("Speciality is required"),
    payment_method: paidToday
      ? yup.string().required("Payment method is required")
      : null,
    visit_date: yup.string().required("Visit date is required"),
    customer_id: yup.string().required("Patient is required"),
    practitioner_id: yup.string().required("Practitioner is required"),
    comment: yup.string(),
    date: paidToday ? yup.string().required("Date is required ") : null,
    // description: paidToday
    //   ? yup.string().required("Description is required ")
    //   : null,
    paid: paidToday ? yup.string().required("Paid status is required ") : null,
    items: paidToday
      ? yup.array().min(1, "At least one item is required")
      : null,
  });

  function CalculateTax(formikProps) {
    const items = formikProps?.values?.items || [];

    const taxableAmount = items
      .filter((itm) => itm?.tax_check)
      .map((itm) => {
        const unitPrice = Number(itm?.unit_price) || 0;
        const quantity = Number(itm?.quantity) || 0;
        return unitPrice * quantity;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const totalDiscount = items
      .filter((itm) => itm?.tax_check)
      .map((itm) => {
        const discountAmount = Number(itm?.discount_amount) || 0;
        const discountType = itm?.discount_type;
        let discount = 0;

        if (discountType === "percentage") {
          discount =
            (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) /
            100;
        } else if (discountType === "fixed") {
          discount = discountAmount;
        }

        return discount;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const taxableAmountAfterDiscount = Math.max(
      taxableAmount - totalDiscount,
      0
    );

    const taxAmount = (taxableAmountAfterDiscount * 15) / 100;

    return taxAmount;
  }

  function CalculateNetTotal(formikProps) {
    const items = formikProps?.values?.items || [];

    const totalAmount = items
      .map((itm) => {
        const unitPrice = Number(itm?.unit_price) || 0;
        const quantity = Number(itm?.quantity) || 0;
        return unitPrice * quantity;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const totalDiscount = items
      .map((itm) => {
        const discountAmount = Number(itm?.discount_amount) || 0;
        const discountType = itm?.discount_type;
        let discount = 0;

        if (discountType === "percentage") {
          discount =
            (Number(itm.unit_price) * Number(itm.quantity) * discountAmount) /
            100;
        } else if (discountType === "fixed") {
          discount = discountAmount;
        }

        return discount;
      })
      .reduce((acc, curr) => acc + curr, 0);

    const taxAmount = CalculateTax(formikProps);

    const netTotal = Math.max(totalAmount - totalDiscount + taxAmount, 0);

    return netTotal;
  }

  useEffect(() => {
    validate === "Cash" && setPayerID("2117");
  }, [validate]);

  const handleSubmit = async (values, { resetForm }) => {
    const appointmentData = {
      service_category: {
        category: values?.service_categories,
      },
      service_type: { type: values?.service_type },
      specialty: { specialty: values?.speciality },
      visit_type: { type: values?.visit_type },
      reason_code: { code: values?.reasons },
      visit_date: values?.visit_date,
      start: values?.slots,
      participant: { participant: "" },
      comment: values?.comment,
      patient_id: values?.customer_id ? String(values.customer_id) : "",
      practitioner_id: values?.practitioner_id,
      payment_method: values?.payment_method,
      payment_status: paidToday ? "Paid" : "UnPaid",
      items: values?.items.map((item) => ({
        ...item,
        discount_amount: Number(item?.discount_amount),
        name: item?.service_desc,
        item_code: item?.service_code,
        item_type: item?.service_type,
        quantity: Number(values?.quantity),
      })),
    };

    const commonData = {
      date: values?.date,
      description: values?.description,
      paid: values?.paid,
      ...(formikRef?.current?.values?.paid === true && {
        account_code: values?.account_code,
      }),
      branch_id: values?.providers_branch_id,
      business_id: values?.providers_id,
      item_details:
        validate === "Cash"
          ? calculateCashDetails(formikRef?.current)
          : calculateShares(formikRef?.current, maxLimit),
      customer_id: Number(hisRef),
      payment_method: values?.payment_method,
      tax_type: "percentage",
      tax_amount: 15,
    };

    const invoiceData =
      values.AppoinmentType === "Cash"
        ? {
            ...commonData,
            invoice_type: "sales",
          }
        : {
            ...commonData,
            invoice_type: "credit",
            company_id: Number(payerLicense),
          };

    try {
      setLoading(true);

      if (paidToday) {
        if (paidToday && appointmentBillToPay) {
          await updateAppoinmentStatus({
            setShowLoader: setLoading,
            data: {
              visit_id: appointmentBillToPay?.visit_id,
              payment_status: "Paid",
            },
          });

          const res = await createNewInvoice(setLoading, invoiceData);

          await updateVisitInvoice(setLoading, {
            id: appointmentBillToPay?.visit_id,
            invoice_number: res?.data?.invoice?.id,
            company_invoice_number: res?.data?.companyInvoice?.id,
          });

          if (res?.data?.invoice_type === "credit") {
            navigate("/receptionist/invoice", {
              state: { type: "credit" },
            });
          } else {
            navigate("/receptionist/invoice", {
              state: { type: "sales" },
            });
          }

          handleClose();
          resetForm();
          setValidate("");
        } else {
          const appointmentResponse = await CreateAppointmentApi(
            setLoading,
            appointmentData
          );
          if (appointmentResponse?.message === "Visit created successfully") {
            const invoiceResponse = await createNewInvoice(
              setLoading,
              invoiceData
            );

            await updateVisitInvoice(setLoading, {
              id: appointmentResponse?.data?.visit_id,
              invoice_number: invoiceResponse?.data?.invoice?.id,
              company_invoice_number: invoiceResponse?.data?.companyInvoice?.id,
            });

            if (invoiceResponse?.data?.invoiceType === "credit") {
              navigate("/receptionist/invoice", {
                state: { type: "credit" },
              });
            } else {
              navigate("/receptionist/invoice", {
                state: { type: "sales" },
              });
            }
          }
          handleClose();
          resetForm();
          setValidate("");
        }
      } else {
        await CreateAppointmentApi(setLoading, appointmentData);
      }
    } catch (error) {
      console.error("Error during appointment or invoice creation:", error);
    } finally {
      setLoading(false);
    }
  };

  const typeOptions = [
    { value: "fixed", label: "Fixed" },
    { value: "percentage", label: "Percentage" },
  ];

  const paid_values = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];

  const ItemsOptions =
    itemsList?.data?.map((item) => ({
      value: item.ID,
      label: item.name,
      amount: item?.price,
    })) || [];

  const handleAppointmentTypeChange = (e) => {
    const selectedValue = e.target.value;
    formikRef?.current?.setFieldValue("AppoinmentType", selectedValue);
    setValidate(selectedValue);
    formikRef?.current?.setFieldValue("items", []);
  };

  const handleCompanySelect = (value) => {
    setPriceListId(value);
    fetchPriceList(value);
  };
  const prevPriceListIdRef = useRef();
  const fetchPriceList = useCallback((id) => {
    if (id && id !== prevPriceListIdRef.current) {
      getPriceListByID(
        setLoading,
        {
          payer_id: String(id),
        },
        setItemsList
      );
      prevPriceListIdRef.current = id;
    }
  }, []);
  const today = new Date().toISOString().split("T")[0];
  const calculateCashDetails = (formikProps) => {
    const items = formikProps?.values?.items || [];

    const detailedItems = items.map((itm) => {
      const unitPrice = Number(itm.unit_price) || 0;
      const quantity = parseFloat(itm.quantity) || 0;

      const itemAmount = unitPrice * quantity;

      const discountAmount = Number(itm?.discount_amount) || 0;
      const discountType = itm?.discount_type || "";
      let itemDiscount = 0;

      if (discountType === "percentage") {
        itemDiscount = (unitPrice * quantity * discountAmount) / 100;
      } else if (discountType === "fixed") {
        itemDiscount = discountAmount;
      }

      // Calculate tax for the specific item
      const itemTax = itm.tax_check
        ? CalculateTax({ values: { items: [itm] } })
        : 0;

      const itemNetTotal = itemAmount - itemDiscount + itemTax;

      return {
        unitPrice,
        quantity: Number(quantity),
        item_code: itm?.service_code || "",
        item_name: itm?.service_desc || "",
        item_type: itm?.service_type || "",
        discount_type: discountType,
        discountAmount: Number(itemDiscount.toFixed(2)),
        discountedAmount: parseFloat((itemAmount - itemDiscount).toFixed(2)),
        itemAmount: parseFloat(itemAmount.toFixed(2)),
        patientShare: parseFloat(itemNetTotal.toFixed(2)),
        companyShare: 0,
        patientTax: parseFloat(itemTax.toFixed(2)),
        companyTax: 0,
      };
    });

    const totalAmount = detailedItems
      .map((itm) => itm.itemAmount)
      .reduce((acc, curr) => acc + curr, 0);

    const totalDiscount = detailedItems
      .map((itm) => itm.discountAmount)
      .reduce((acc, curr) => acc + curr, 0);

    const totalTax = detailedItems
      .map((itm) => itm.patientTax)
      .reduce((acc, curr) => acc + curr, 0);

    const netTotal = detailedItems
      .map((itm) => itm.patientShare)
      .reduce((acc, curr) => acc + curr, 0);

    return {
      itemBreakdown: detailedItems,
      totals: {
        patientShareTotal: parseFloat(totalAmount.toFixed(2)),
        companyShareTotal: 0,
        patientTaxTotal: parseFloat(totalTax.toFixed(2)),
        companyTaxTotal: 0,
        discountedAmount: parseFloat(totalDiscount.toFixed(2)),
        totalAmount:
          parseFloat(totalAmount.toFixed(2)) -
          parseFloat(totalDiscount.toFixed(2)) +
          parseFloat(totalTax.toFixed(2)),
      },
    };
  };
  useEffect(() => {
    if (payersData?.length) {
      const firstAvailablePayer = payersData.flatMap((category) =>
        category.payers.filter((insurance) =>
          insurancePlan?.some((plan) => plan.payer_id === insurance.id)
        )
      )[0];
      if (firstAvailablePayer) {
        formikRef?.current?.setFieldValue("payer", firstAvailablePayer.id);
        setPayerID(firstAvailablePayer.id);
        setPayerLicense(firstAvailablePayer?.license_id);
      }
    }
  }, [payersData, payerId, formikRef?.current?.values.payer, insurancePlan]);
  async function InsurancePlanFunc(id) {
    const data = await getPatientByID(null, id, setInsurancePlan, false);
    setInsurancePlan(data?.data?.insurance_plans);
  }
  useEffect(() => {
    const result = insurancePlan?.filter((plan) => plan?.payer_id == payerId);
    setSelectedInsurancePlan(result ? result[0] : null);
    setMaxLimit(selectedInsurancePlan?.max_limit);
    setPatientShare(selectedInsurancePlan?.patient_share);
  }, [payerId, payersData]);

  const [searchValue] = useState("");

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {(formikProps) => (
          <div className="flex h-screen rounded-md bg-gray-100 p-2">
            <div
              className={`${
                validate === "" || !paidToday ? "w-full" : "w-3/4"
              } pr-2 overflow-auto`}
            >
              <h2 className="text-xl font-semibold mb-2 flex items-center gap-1 bg-white shadow-md p-2 rounded-md text-gray-800">
                <div
                  className="flex justify-end items-end cursor-pointer"
                  onClick={() => navigate("/receptionist/visits")}
                >
                  <IconButton
                    aria-label="go back"
                    className="!mr-2 !w-[30px] !h-[30px] !text-white !bg-red-400 !rounded-full"
                  >
                    <FaArrowLeft />
                  </IconButton>
                </div>
                {appointmentBillToPay ? "Pay Bill" : " Create New Visit"}
              </h2>
              <Form onSubmit={formikProps.handleSubmit}>
                <div className="bg-white rounded-lg shadow-md p-3 mb-2">
                  <div className="grid grid-cols-4 gap-[0.5rem]">
                    <SharedInput
                      name="visit_date"
                      type="date"
                      label="Visit Date"
                      autoFocus
                      value={formikProps.values.start}
                      onChange={(e) => {
                        formikProps.handleChange(e);

                        const startDate = new Date(e.target.value);
                        const currentDate = new Date();

                        startDate.setHours(0, 0, 0, 0);
                        currentDate.setHours(0, 0, 0, 0);

                        const isInFuture = startDate > currentDate;
                        setPaidToday(!isInFuture);
                        // if (
                        //   formikProps?.values?.practitioner_id &&
                        //   formikProps?.values?.visit_date
                        // ) {
                        //   getslots(setLoading, setSlotList, {
                        //     practitioner_id:
                        //       formikProps?.values?.practitioner_id,
                        //     visit_date: formikProps?.values?.visit_date,
                        //   });
                        // }
                      }}
                    />

                    <SharedSearchDropdown
                      name="customer_id"
                      label="Patient"
                      optionLabel="Select Patient"
                      data={patientList?.data
                        ?.filter((patient) =>
                          [
                            patient.primary_contact_number,
                            patient.his_refrence,
                            patient.name,
                          ]
                            .join(" ")
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        )
                        ?.map((patient) => ({
                          value: patient.his_refrence,
                          label: `${patient.name} (${patient.file_number} - ${patient.document_id})`,
                          refrence: patient.id,
                        }))}
                      onItemAdd={(value) => {
                        const selectedPatient = patientList?.data?.find(
                          (patient) => patient.his_refrence === value
                        );

                        if (selectedPatient && patientList?.data) {
                          formikProps.setFieldValue(
                            "AppoinmentType",
                            selectedPatient.beneficiary_type
                          );
                          setValidate(selectedPatient.beneficiary_type);
                          // setMaxLimit(selectedPatient.max_limit);
                          setRef(selectedPatient.id);
                          InsurancePlanFunc(selectedPatient?.his_refrence);

                          const storedPayers =
                            JSON.parse(localStorage.getItem("payersData")) ||
                            {};
                          if (storedPayers.payers) {
                            const selectedPayer = storedPayers.payers.find(
                              (payer) => payer.flag === true
                            );

                            if (selectedPayer) {
                              formikProps.setFieldValue(
                                "payer",
                                selectedPayer.id
                              );
                              setPayerID(selectedPayer.id);
                            }
                          }
                        } else {
                          console.error(
                            "Selected patient or patient list is undefined."
                          );
                        }
                      }}
                    />

                    {paidToday && (
                      <div className="flex gap-1 flex-col">
                        <label className="text-sm">Patient Type</label>

                        <div className="flex w-full">
                          <button
                            type="button"
                            onClick={() => {
                              formikProps.setFieldValue(
                                "AppoinmentType",
                                "Cash"
                              );
                              setValidate("Cash");
                              setPayerID("2117");
                            }}
                            className={`w-[50%] border px-1.5 py-2 transition-all rounded-tl-md rounded-bl-md  !border-r-0  ${
                              formikProps.values.AppoinmentType === "Cash"
                                ? "!bg-primary !border-primary text-white"
                                : null
                            }`}
                          >
                            Cash
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              formikProps.setFieldValue(
                                "AppoinmentType",
                                "Insurance"
                              );
                              setValidate("Insurance");
                            }}
                            className={`w-[50%] border px-1.5 py-2 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                              formikProps.values.AppoinmentType === "Insurance"
                                ? "!bg-primary !border-primary text-white"
                                : null
                            }`}
                          >
                            Insurance
                          </button>
                        </div>
                        {formikProps.errors.AppoinmentType &&
                        formikProps.touched.AppoinmentType ? (
                          <p className="max-w-2xl text-sm leading-6 text-red-500">
                            {formikProps.errors.AppoinmentType}
                          </p>
                        ) : null}
                      </div>
                    )}

                    <SharedSearchDropdown
                      name="practitioner_id"
                      label="Doctor"
                      optionLabel="Select Doctor"
                      data={doctorList?.data?.map((doctor) => ({
                        value: doctor.practitioner_id,
                        label: doctor.name,
                        speciality: doctor?.speciality,
                      }))}
                      onItemAdd={(value) => {
                        // if (formikProps?.values?.visit_date) {
                        //   getslots(setLoading, setSlotList, {
                        //     practitioner_id: value,
                        //     visit_date: formikProps?.values?.visit_date,
                        //   });
                        // }
                        const selectedDoctor = doctorList?.data?.find(
                          (doc) => doc.practitioner_id === value
                        );

                        if (selectedDoctor) {
                          formikProps.setFieldValue(
                            "speciality",
                            selectedDoctor.speciality
                          );
                        }
                      }}
                    />
                    {formikProps.values.AppoinmentType === "Insurance" &&
                      paidToday && (
                        <div className="flex flex-col gap-1">
                          <label htmlFor="payer_id">Payer*</label>
                          <select
                            id="payer"
                            name="payer"
                            onChange={(event) => {
                              formikProps.handleChange(event);
                              const selectedPayerId = event.target.value;
                              setPayerID(selectedPayerId);
                              const selectedPayerLicense =
                                event.target.options[event.target.selectedIndex]
                                  .dataset.license;
                              setPayerLicense(selectedPayerLicense);
                            }}
                            value={formikProps.values.payer}
                            autoComplete="payer"
                            className="w-full border rounded-lg"
                          >
                            <option value="" data-license={""}>
                              Select Payer
                            </option>
                            {(() => {
                              return payersData?.map((category) => {
                                const relevantPayers = category.payers.filter(
                                  (insurance) =>
                                    insurancePlan?.some(
                                      (plan) => plan.payer_id === insurance.id
                                    )
                                );
                                return (
                                  <optgroup
                                    label={`${
                                      category?.name_en === null
                                        ? ""
                                        : category?.name_en
                                    } ${
                                      category?.license_id === null
                                        ? ""
                                        : category?.license_id
                                    }`}
                                    key={category.id}
                                  >
                                    {relevantPayers.length > 0 ? (
                                      relevantPayers.map((insurance, index) => (
                                        <option
                                          key={index}
                                          value={insurance.id}
                                          data-license={insurance.license_id}
                                        >
                                          {insurance?.name_en} (
                                          {insurance?.license_id})
                                        </option>
                                      ))
                                    ) : (
                                      <option disabled>
                                        No payer for this category
                                      </option>
                                    )}
                                  </optgroup>
                                );
                              });
                            })()}
                          </select>

                          {formikProps.errors.payer &&
                            formikProps.touched.payer && (
                              <p className="max-w-2xl text-sm leading-6 text-red-500">
                                {formikProps.errors.payer}
                              </p>
                            )}
                        </div>
                      )}

                    <SharedInput
                      name="speciality"
                      type="text"
                      label="Speciality"
                      placeholder={"Doctor's Speciality"}
                      disabled="true"
                    />

                    <SharedDropdown
                      name="visit_type"
                      label="Visit Type"
                      optionLabel="Select"
                      data={appointmentTypes}
                    />

                    {paidToday && (
                      <>
                        <SharedDropdown
                          name="payment_method"
                          label="Payment Method"
                          optionLabel="Select"
                          data={paymentMethods}
                        />
                        <div>
                          <label className="text-sm">Paid</label>

                          <div className="flex w-full">
                            <button
                              type="button"
                              onClick={() => {
                                formikProps.setFieldValue(`paid`, true);
                              }}
                              className={`w-[50%] border p-2 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                                formikProps.values.paid === true
                                  ? "!bg-primary !border-primary text-white"
                                  : ""
                              }`}
                            >
                              Paid
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                formikProps.setFieldValue(`paid`, false);
                              }}
                              className={`w-[50%] border p-2 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                                formikProps.values.paid === false
                                  ? "!bg-primary !border-primary text-white"
                                  : ""
                              }`}
                            >
                              Unpaid
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {formikProps?.values?.paid && (
                      <SharedSearchDropdown
                        name="account_code"
                        label="Account"
                        optionLabel="Select Account"
                        data={cashAccount?.data?.map((acc) => ({
                          value: Number(acc.code),
                          label: acc.name_en,
                        }))}
                      />
                    )}
                    <SharedInput
                      name="comment"
                      label="Comment"
                      placeholder={"Add Comment"}
                    />
                  </div>
                </div>

                {formikProps.values.AppoinmentType !== "" && paidToday && (
                  <div className="bg-white rounded-lg shadow-md p-6 mb-2">
                    <h3 className="mb-2 font-semibold">Invoice date</h3>
                    <div
                      className={`grid ${
                        validate === "Insurance" ? "grid-cols-4" : "grid-cols-3"
                      } gap-[0.5rem]`}
                    >
                      <SharedInput
                        name="date"
                        label="Date"
                        type="datetime-local"
                      />
                      <SharedInput
                        name="description"
                        label="Description"
                        placeholder={"Enter Description"}
                      />
                    </div>
                  </div>
                )}

                {formikProps.values.AppoinmentType !== "" && paidToday && (
                  <div className="bg-white rounded-lg shadow-md p-6 mb-2">
                    <h3 className="text-xl font-semibold mb-3 text-gray-700">
                      Items
                    </h3>

                    <div className="field flex flex-col gap-1 relative">
                      <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                        <BiSearch className="h-5 w-5 text-gray-400" />
                      </span>
                      <input
                        type="text"
                        placeholder="Search for item by Service Code or Service Description"
                        onChange={handleSearchChange}
                        value={searchTerm}
                        
                        className="block w-full py-2 !px-10 !rounded-md"
                      />
                      {searchTerm.length > 0 && (
                        <span
                          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                          onClick={() => {
                            setSearchResult([]);
                            setSearchTerm("");
                          }}
                        >
                          <BiX className="h-7 w-7 text-gray-400" />
                        </span>
                      )}
                      {searchResult.length > 0 ? (
                        <ul className="absolute top-full left-0 z-50 bg-white border border-gray-300 rounded-md mt-1 w-full max-h-72 overflow-y-auto">
                          {searchResult.map((item, index) => (
                            <li
                              key={index}
                              onClick={() =>
                                handleSelectSearchItem(formikProps, item)
                              }
                              className="py-1 px-3 cursor-pointer hover:bg-gray-100 border-b border-gray-300"
                            >
                              <div className="flex gap-3">
                                <div className="my-auto w-36 font-semibold capitalize ">
                                  {item?.service_type}
                                </div>
                                <div className="pl-3 border-l border-gray-500">
                                  <div className="border-b border-gray-200">
                                    {item?.service_code}
                                    {item?.service_desc}
                                  </div>
                                  <div>
                                    {item?.non_standard_code}
                                    {item?.non_standard_desc}
                                  </div>
                                </div>
                              </div>
                              {/* Assuming item has a 'name' property */}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        searchTerm.length > 3 && (
                          <ul className="absolute top-full left-0 z-50 bg-white border border-gray-300 rounded-md mt-1 w-full max-h-72 overflow-y-auto">
                            <li className="py-1 px-3 cursor-pointer font-medium text-center text-lg">
                              No Result Found
                            </li>
                          </ul>
                        )
                      )}
                    </div>
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Name
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Unit Price
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Quantity
                            </th>
                            {formikProps?.values?.AppoinmentType ===
                              "Insurance" && (
                              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Patient Share
                              </th>
                            )}
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Discount %
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Tax 15%
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {formikProps.values.items.map((item, index) => (
                            <tr key={index} className="bg-white border-b">
                              <td className="px-4 py-2">
                                {item?.service_desc}
                              </td>
                              <td className="px-4 py-2">
                                <SharedInput
                                  type="number"
                                  name={`items[${index}].unit_price`}
                                  value={item.unit_price}
                                  className="!w-20"
                                  min={0}
                                />
                              </td>
                              <td className="px-4 py-2">
                                <SharedInput
                                  type="number"
                                  name={`items[${index}].quantity`}
                                  value={item.quantity}
                                  className="!w-20"
                                  min={0}
                                />
                              </td>
                              {formikProps?.values?.AppoinmentType ===
                              "Insurance" ? (
                                <td className="px-4 py-2">
                                  <SharedInput
                                    name={`items[${index}].patient_share`}
                                    value={item.patient_share}
                                    type="number"
                                    className="!w-24"
                                  />
                                </td>
                              ) : null}
                              <td className="px-4 py-2">
                                <div className="flex items-center space-x-2">
                                  <SharedDropdown
                                    name={`items[${index}].discount_type`}
                                    value={item.discount_type}
                                    data={typeOptions}
                                    optionLabel="select"
                                    // disabled={true}
                                    className="!w-24"
                                  />
                                  <SharedInput
                                    type="number"
                                    name={`items[${index}].discount_amount`}
                                    value={item.discount_amount}
                                    className="!w-20"
                                    min={0}
                                  />
                                </div>
                              </td>

                              <td className="px-4 py-2">
                                <div className="flex w-full">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      formikProps.setFieldValue(
                                        `items[${index}].tax_check`,
                                        false
                                      );
                                    }}
                                    className={`w-[50%] border p-2 transition-all rounded-tl-md rounded-bl-md !border-r-0 ${
                                      formikProps.values.items[index]
                                        .tax_check === false
                                        ? "!bg-primary !border-primary text-white"
                                        : ""
                                    }`}
                                  >
                                    False
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      formikProps.setFieldValue(
                                        `items[${index}].tax_check`,
                                        true
                                      );
                                    }}
                                    className={`w-[50%] border p-2 !border-l-0 transition-all rounded-tr-md rounded-br-md ${
                                      formikProps.values.items[index]
                                        .tax_check === true
                                        ? "!bg-primary !border-primary text-white"
                                        : ""
                                    }`}
                                  >
                                    True
                                  </button>
                                </div>
                              </td>
                              <td className="px-4 py-2">
                                <Button
                                  color="error"
                                  onClick={() => {
                                    const newItems =
                                      formikProps.values.items.filter(
                                        (_, i) => i !== index
                                      );
                                    formikProps.setFieldValue(
                                      "items",
                                      newItems
                                    );
                                  }}
                                >
                                  <RiDeleteBin5Line />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                <div className="flex justify-end mt-6">
                  <Button
                    variant="contained"
                    type="submit"
                    size="large"
                    style={{
                      backgroundImage:
                        "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                    }}
                    className="px-6 py-2 text-white font-semibold rounded-md shadow-sm hover:shadow-md transition-all duration-300"
                  >
                    {appointmentBillToPay ? "Pay" : "Create Visit"}
                  </Button>
                </div>
              </Form>
              <AppLoader setShowLoader={setLoading} showLoader={loading} />
            </div>

            {validate !== "" && paidToday && (
              <div className="w-1/4 bg-white rounded-lg shadow-md p-4 h-fit">
                <h3 className="text-xl font-medium mb-3 text-gray-800">
                  Bill Summary
                </h3>
                <div className="space-y-2">
                  {formikProps?.values?.AppoinmentType === "Insurance" && (
                    <div className="bg-gray-50 p-2 rounded-lg">
                      <div className="flex justify-between">
                        <h4 className="text-md font-medium text-gray-700">
                          Total Discount:
                        </h4>
                        <span className="font-semibold">
                          {formikProps?.values?.AppoinmentType === "Cash"
                            ? formikProps?.values?.items
                                ?.map((itm) => {
                                  const discountAmount =
                                    Number(itm?.discount_amount) || 0;
                                  const discountType = itm?.discount_type;
                                  let discount = 0;

                                  if (discountType === "percentage") {
                                    discount =
                                      (Number(itm.unit_price) *
                                        Number(itm.quantity) *
                                        discountAmount) /
                                      100;
                                  } else if (discountType === "fixed") {
                                    discount = discountAmount;
                                  }

                                  return discount;
                                })
                                .reduce((acc, curr) => acc + curr, 0)
                            : calculateShares(formikProps, maxLimit).totals
                                ?.discountedAmount}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="bg-gray-50 p-2 rounded-lg">
                    <h4 className="text-md font-medium text-gray-700">
                      Cash Bill
                    </h4>
                    <div className="space-y-1">
                      <div className="flex justify-between">
                        <span className="text-gray-600 text-sm">Amount:</span>
                        <span className="font-semibold text-sm">
                          {formikProps?.values?.AppoinmentType === "Cash"
                            ? calculateCashDetails(formikProps)?.totals
                                ?.patientShareTotal
                            : calculateShares(formikProps, maxLimit).totals
                                ?.patientShareTotal}
                        </span>
                      </div>
                      {formikProps?.values?.AppoinmentType === "Cash" && (
                        <div className="flex justify-between">
                          <span className="text-gray-600 text-sm">
                            Discount:
                          </span>
                          <span className="font-semibold text-sm">
                            {formikProps?.values?.AppoinmentType === "Cash"
                              ? calculateCashDetails(formikProps)?.totals
                                  ?.discountedAmount
                              : calculateShares(formikProps, maxLimit).totals
                                  ?.discountedAmount}
                          </span>
                        </div>
                      )}
                      <div className="flex justify-between">
                        <span className="text-gray-600 text-sm">Tax:</span>
                        <span className="font-semibold text-sm">
                          {formikProps?.values?.AppoinmentType === "Cash"
                            ? calculateCashDetails(formikProps)?.totals
                                ?.patientTaxTotal
                            : calculateShares(formikProps, maxLimit).totals
                                ?.patientTaxTotal}
                        </span>
                      </div>
                      <div className="flex justify-between text-md font-semibold">
                        <span className="text-gray-600">Net Total:</span>
                        <span>
                          {/* {formikProps?.values?.AppoinmentType === "Cash"
                            ? calculateCashDetails(formikProps)?.totals
                                ?.patientShareTotal -
                              calculateCashDetails(formikProps, maxLimit)
                                ?.totals?.discountedAmount +
                              calculateCashDetails(formikProps, maxLimit).totals
                                ?.patientTaxTotal
                            : calculateShares(formikProps, maxLimit).totals
                                ?.patientShareTotal +
                              calculateShares(formikProps, maxLimit).totals
                                ?.patientTaxTotal} */}
                          {formikProps?.values?.AppoinmentType === "Cash"
                            ? (
                                calculateCashDetails(formikProps)?.totals
                                  ?.patientShareTotal -
                                  calculateCashDetails(formikProps, maxLimit)
                                    ?.totals?.discountedAmount +
                                  calculateCashDetails(formikProps, maxLimit)
                                    ?.totals?.patientTaxTotal || 0
                              ).toFixed(2)
                            : (
                                calculateShares(formikProps, maxLimit)?.totals
                                  ?.patientShareTotal +
                                  calculateShares(formikProps, maxLimit)?.totals
                                    ?.patientTaxTotal || 0
                              ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>

                  {validate === "Insurance" && paidToday && (
                    <div className="bg-gray-50 p-2 rounded-lg">
                      <h4 className="text-md font-medium text-gray-700">
                        Company Bill
                      </h4>
                      <div className="space-y-1">
                        <div className="flex justify-between">
                          <span className="text-gray-600 text-sm">Amount:</span>
                          <span className="font-semibold text-sm">
                            {
                              calculateShares(formikProps, maxLimit).totals
                                ?.companyShareTotal
                            }
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-gray-600 text-sm">Tax:</span>
                          <span className="font-semibold text-sm">
                            {
                              calculateShares(formikProps, maxLimit).totals
                                ?.companyTaxTotal
                            }
                          </span>
                        </div>
                        <div className="flex justify-between text-md font-semibold">
                          <span className="text-gray-600">Net Total:</span>
                          <span>
                            {calculateShares(formikProps, maxLimit).totals
                              ?.companyShareTotal +
                              calculateShares(formikProps, maxLimit).totals
                                ?.companyTaxTotal}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <AppLoader setShowLoader={setLoading} showLoader={loading} />
              </div>
            )}
          </div>
        )}
      </Formik>
    </>
  );
}

export default NewAppointment;
