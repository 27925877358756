import { useFormikContext, Field } from "formik";
import React from "react";

export default function SupportingInfoVitalSigns() {
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const handleDateChange = (field, value) => {
    // Update the date for all vital signs
    const updatedVitalSigns = values.vitalSigns.map((vitalSign) => ({
      ...vitalSign,
      [field]: value,
    }));
    setFieldValue("vitalSigns", updatedVitalSigns);
  };
  const formatDateToMMDDYYYY = (date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0"); 
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  };

  // mm/dd/yyyy to yyyy-mm-dd
  const formatToInputDate = (dateString) => {
    const [month, day, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="p-3 mb-2 !bg-gray-100 rounded shadow-sm">
      <div className="inventory-svg mb-2 flex gap-3 items-center">
        <div class="inventory-svg">
          <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="34" height="34" rx="8" fill="#F4F6F8"></rect>
            <path
              d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
              fill="#F5793B"
            ></path>
            <path
              d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
              fill="#F5793B"
            ></path>
          </svg>
        </div>
        <div className="text-[#344054] text-[18px] font-semibold">
          Vital Signs
        </div>
      </div>

      <div className="grid grid-cols-2 gap-2 mb-2">
        <div className="flex flex-col">
          <label className="text-sm">Start Date:</label>
          <input
            type="date"
            value={
              values.vitalSigns[0]?.startDate
                ? formatToInputDate(values.vitalSigns[0]?.startDate)
                : new Date().toISOString().split("T")[0]
            }
            onChange={(e) =>
              handleDateChange(
                "startDate",
                formatDateToMMDDYYYY(e.target.value)
              )
            } // Handle date change
            className={`border p-2 rounded text-sm ${
              touched.vitalSigns?.[0]?.startDate &&
              errors.vitalSigns?.[0]?.startDate
                ? "border-red-500"
                : ""
            }`}
          />

          {touched.vitalSigns?.[0]?.startDate &&
            errors.vitalSigns?.[0]?.startDate && (
              <div className="text-red-500 text-xs">
                {errors.vitalSigns[0].startDate}
              </div>
            )}
        </div>

        <div className="flex flex-col">
          <label className="text-sm">End Date:</label>
          <input
            type="date"

            value={
              values.vitalSigns[0]?.endDate
                ? formatToInputDate(values.vitalSigns[0]?.endDate)
                : new Date().toISOString().split("T")[0]
            }
            onChange={(e) =>
              handleDateChange(
                "endDate",
                formatDateToMMDDYYYY(e.target.value)
              )
            }

            // value={values.vitalSigns[0]?.endDate || ""}
            // onChange={(e) => handleDateChange("endDate", e.target.value)}
            className={`border p-2 rounded text-sm ${
              touched.vitalSigns?.[0]?.endDate &&
              errors.vitalSigns?.[0]?.endDate
                ? "border-red-500"
                : ""
            }`}
          />
          {touched.vitalSigns?.[0]?.endDate &&
            errors.vitalSigns?.[0]?.endDate && (
              <div className="text-red-500 text-xs">
                {errors.vitalSigns[0].endDate}
              </div>
            )}
        </div>
      </div>

      <div className="grid grid-cols-4 md:grid-cols-7 gap-2">
        {values.vitalSigns.map((vitalSign, index) => (
          <div key={index} className="flex w-full">
            <div className="flex flex-col w-[75%]">
              <label className="text-xs capitalize">
                {vitalSign.category.replace(/-/g, " ")}
              </label>
              <Field
                name={`vitalSigns[${index}].value`}
                type="number"
                className={`border p-2 text-sm rounded-l ${
                  touched.vitalSigns?.[index]?.value &&
                  errors.vitalSigns?.[index]?.value
                    ? "border-red-500"
                    : ""
                }`}
                placeholder={`Enter ${vitalSign.category}`}
              />
              {touched.vitalSigns?.[index]?.value &&
                errors.vitalSigns?.[index]?.value && (
                  <div className="text-red-500 text-xs">
                    {errors.vitalSigns[index].value}
                  </div>
                )}
            </div>

            <div className="flex flex-col w-[25%]">
              <label className="text-xs">Unit</label>
              <Field
                name={`vitalSigns[${index}].valueUnit`}
                type="text"
                className="border p-2 text-sm rounded-r !bg-primary text-white"
                disabled
                value={vitalSign.valueUnit}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
