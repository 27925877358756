import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import AppLoader from "../../../components/AppLoader";
import BeneficiaryInfo from "../../PreAuth/components/request/BeneficiaryInfo";
import PreAuthInfo from "../../PreAuth/components/request/PreAuthInfo";
import CareTeam from "../../PreAuth/components/request/CareTeam";
import Diagnosis from "../../PreAuth/components/request/Diagnosis";
import ItemsList from "../../PreAuth/components/request/ItemsList";
import IsAccident from "../../PreAuth/components/request/IsAccident";
import EncounterForm from "../../PreAuth/components/request/EncounterForm";
import EmrSupportingINfo from "./EmrSupportingINfo";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
  Tooltip,
} from "@mui/material";
import { Vaccines } from "@mui/icons-material";
import AllergyIntolerance from "./AllergyIntolerance";
import {
  CreateNewEmr,
  getPractitionerDetail,
  getRcmPatientByID,
} from "./ApiMethods";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import EmrPreAuthModel from "./EmrPreAuthModel";
import { useMutation } from "@apollo/client";
import preAuth from "../../../gql/preAuth";
import SupportingInfoVitalSigns from "./SupportingInfoVitalSigns";
import DiagnosisSearchNSelect from "../../PreAuth/components/request/DiagnosisSearchNSelect";
import AddIcon from "@mui/icons-material/Add";

export default function EmrForm() {
  function classNames(...classes) {
    return classes?.filter(Boolean).join(" ");
  }
  const navigate = useNavigate();
  const currentDate = new Date();
  const location = useLocation();
  const emr = location.state?.emr;

  const patient = location?.state?.appointment;
  const token = JSON.parse(localStorage.getItem("user")).jwt;
  const appointmentId = location?.state?.appointmentId;
  const [Loading, setLoading] = useState(false);
  const [detail, setDetail] = useState("");
  const [rcmDetail, setRcmDetail] = useState(null);
  useEffect(() => {
    getRcmPatientByID(patient?.patient?.rcm_ref).then((res) => {
      setRcmDetail(res?.data);
    });
    if (token) {
      getPractitionerDetail(setLoading, setDetail, token);
    }
  }, []);
  const formikRef = useRef(null);

  const excludedCategories = [
    "vital-sign-systolic",
    "vital-sign-diastolic",
    "vital-sign-height",
    "vital-sign-weight",
    "respiratory-rate",
    "temperature",
    "pulse",
  ];

  const formInitialValues = {
    past_history: emr?.past_history || "",
    family_history: emr?.family_history || "",
    doctor_remarks: emr?.doctor_remarks || "",
    er_case: emr?.er_case || false,
    chronic: emr?.chronic || false,
    congential: emr?.congential || false,
    rta: emr?.rta || false,
    work_related: emr?.work_related || false,
    vaccination: emr?.vaccination || false,
    checkup: emr?.checkup || false,
    psychiatric: emr?.psychiatric || false,
    infertility: emr?.infertility || false,
    pregnancy: emr?.pregnancy || false,
    lactation: emr?.lactation || false,
    acute: emr?.acute || false,
    patientID: emr?.patient?.patient_id || "",
    payerId: "",
    dateOrdered: emr?.created_at
      ? new Date(emr.created_at).toISOString().substring(0, 10)
      : currentDate.toISOString().substring(0, 10),
    claimType: "",
    careTeamsArr: emr?.careTeamsArr || [
      {
        practitioner: detail ? detail?.data?.name : "",
        practitionerName: detail ? detail?.data?.name : "",
        practitioner_role: detail ? detail?.data?.role : "",
        // care_team_role: "",
        care_team_role: "",
        qualification: detail ? detail?.data?.speciality : "",
      },
    ],
    diagnosisArr: emr?.diagnosisArr || [],
    visit_id: emr?.visit_id || "",
    // supportingInfos: emr?.supportingInfos || [
    supportingInfos: emr?.supportingInfos?.filter(
      (info) => !excludedCategories.includes(info.category)
    ) || [
      {
        category: "chief-complaint",
        codeText: "",
        code: "",
        valueUnit: "",
        value: "",
      },
    ],
    vitalSigns: [
      {
        category: "vital-sign-systolic",
        startDate: "",
        // startDate: emr?.supportingInfos.find(info => info.category === "vital-sign-systolic")?.startDate || "",
        endDate: "",
        // endDate: emr?.supportingInfos.find(info => info.category === "vital-sign-systolic")?.endDate || "",
        // value: emr?.supportingInfos.category == "vital-sign-systolic" ? emr?.supportingInfos?.value : "",
        value:
          emr?.supportingInfos?.find(
            (info) => info.category === "vital-sign-systolic"
          )?.value || "",
        valueUnit: "mm[Hg]",
      },
      {
        category: "vital-sign-diastolic",
        startDate: "",
        endDate: "",
        // value: "",
        value:
          emr?.supportingInfos?.find(
            (info) => info.category === "vital-sign-diastolic"
          )?.value || "",
        valueUnit: "mm[Hg]",
      },
      {
        category: "vital-sign-height",
        startDate: "",
        endDate: "",
        value:
          emr?.supportingInfos?.find(
            (info) => info.category === "vital-sign-height"
          )?.value || "",
        valueUnit: "cm",
      },
      {
        category: "vital-sign-weight",
        startDate: "",
        endDate: "",
        value:
          emr?.supportingInfos?.find(
            (info) => info.category === "vital-sign-weight"
          )?.value || "",
        valueUnit: "kg",
      },
      {
        category: "temperature",
        startDate: "",
        endDate: "",
        value:
          emr?.supportingInfos?.find((info) => info.category === "temperature")
            ?.value || "",
        valueUnit: "Cel",
      },
      {
        category: "respiratory-rate",
        startDate: "",
        endDate: "",
        value:
          emr?.supportingInfos?.find(
            (info) => info.category === "respiratory-rate"
          )?.value || "",
        valueUnit: "/min",
      },
      {
        category: "pulse",
        startDate: "",
        endDate: "",
        value:
          emr?.supportingInfos?.find((info) => info.category === "pulse")
            ?.value || "",
        valueUnit: "/min",
      },
    ],
    items: emr?.items || [],
    encounterStatus: emr?.encounterStatus || null,
    immunization: emr?.immunization || {
      status: "",
      occurrenceDateTime: "",
      vaccine_code: "",
    },
    isImmunization: emr?.isImmunization || false,
    is_accident: emr?.is_accident || false,
    allergyIntolerance: emr?.allergyIntolerance || [],
  };
  const statusValues = [
    {
      id: "compelete",
      value: "compeleted",
    },
    {
      id: "pending",
      value: "pending",
    },
  ];

  const [open, setOpen] = useState(false);
  const preAuthValidationSchema = Yup.object().shape({
    careTeamsArr: Yup.array().min(1, "Atleast One is reduired"),
    diagnosisArr: Yup.array().min(1, "Atleast One is reduired"),
    items: Yup.array().min(1, "Atleast One is reduired"),
    encounterStatus: Yup.string().nullable(),
    vitalSigns: Yup.array().of(
      Yup.object().shape({
        category: Yup.string().required("Category is required"),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date()
          .required("End date is required")
          .min(Yup.ref("startDate"), "End date cannot be before start date"),
        value: Yup.number()
          .required("Value is required")
          .positive("Value must be a positive number"),
        valueUnit: Yup.string().required("Value unit is required"),
      })
    ),
    // is_accident: Yup.boolean().required("Accident is required"),
    // is_accident: Yup.boolean(),
    // accidentType: Yup.string().required("Accident Type is required"),
    // street: Yup.string().required("Street name is required"),
    // city: Yup.string().required("City is required"),
    // state: Yup.string().required("State is required"),
    // country: Yup.string().required("Country is required"),
    // date: Yup.date().required("Date is required"),
    
    // immunization: Yup.object().shape({
    //   status: Yup.string().required("Immunization status is required"),
    //   occurrenceDateTime: Yup.date()
    //     .required("Occurrence DateTime is required")
    //     .nullable(),
    //   vaccine_code: Yup.string().required("Vaccine Code is required"),
    // }),
    isImmunization: Yup.boolean(),
    // allergyIntolerance: Yup.array().min(1, "Atleast One is reduired"),
  });

  function convertClinicalData(inputData) {
    return inputData.map((item) => {
      const baseObject = {
        category: item.category,
        code: item.code || null,
        code_text: item.codeText || null,
        start_date: item.startDate || null,
        end_date: item.endDate || null,
        value: item.value || null,
        unit: item.valueUnit || null,
        reason: item.reason || null,
        image_content_type: item.imageContentType || null,
        image_title: item.imageTitle || null,
        image_creation_date: item.imageCreationDate || null,
        image_value: item.imageValue || null,
      };

      if (item.category.startsWith("vital-sign-")) {
        baseObject.code = null;
        baseObject.code_text = null;
      }

      if (item.image) {
        baseObject.image_content_type = item.image.contentType || null;
        baseObject.image_title = item.image.title || null;
        baseObject.image_creation_date = item.image.creationDate || null;
        baseObject.image_value = item.image.value || null;
      }

      return baseObject;
    });
  }
  const [requestPreAuth, { data, loading, error }] = useMutation(
    preAuth.REQUEST_AUTHORIZATION,
    {
      onCompleted(result) {
        toast.success(result?.preAuthRequest?.message);
        if (result?.preAuthRequest?.message === "data sent to payer") {
          CreateNewEmr(setLoading, formikRef?.current?.values).then(() => {
            navigate("/his/patient");
          });
        }
        if (result?.preAuthRequest?.nphies_err) {
          result?.preAuthRequest?.nphies_err.map((err) => {
            toast?.error(err);
          });
        }
        setOpen(false);
      },
      onError(err) {
        console.error("Pre-Authorization Request Error:", err);
      },
    }
  );
  const requestPreAuthApi = (values) => {
    const updateddiagnosis = values?.diagnosisArr?.map((des) => ({
      type: des?.type,
      on_admission: des?.on_admission === "false" ? false : true,
      code: des?.codeId,
      description: des?.codeDescription,
    }));
    const UpdatedItems = values?.items.map((item) => ({
      type: item.type,
      standard_code: item.standardCode,
      standard_description: item.standardCodeDescription,
      non_standard_code: item.non_standard_code,
      non_standard_description: item.non_standard_description,
      medication_selection_reason: item.medicationSelectionReason,
      prescribed_medication: Number(item.prescribedMedication),
      body_site: item.body_site || null,
      sub_site: item.sub_site || "",
      service_start_date: item.service_start_date,
      service_end_date: item.service_end_date || "",
      supporting_info: item.supporting_info || [],
      diagnosis: item.diagnosis,
      care_teams: item.care_teams,
      // is_package:
      //   item.is_package === "false" ? false : Boolean(item?.is_package),
      is_package:
        item.is_package === String(item?.is_package),
      quantity: item.quantity,
      code: item.standardCode,
      unit_price: item.unit_price,
      tax_amount: item.tax_amount,
      factor: item.factor,
      // net: Number(item.net),
      net: parseFloat(item?.net ? item.net : 0),
      discount_amount: item.discount_amount,
      patient_share: item.patient_share,
      patient_share_amount: item.patientShareAmount,
      payer_share: String(item.payer_share),
    }));
    const mappedValues = {
      is_edit_request: false,
      ref_transaction_id: null,
      is_followup_req: false,
      prv_transaction_id: "",
      patient_id: rcmDetail?.id,
      insurance_plan_id: rcmDetail?.insurance_plans[0]?.id,
      is_new_born: false,
      is_referral: false,
      date_ordered: currentDate.toISOString().substring(0, 10),
      priority: "stat",
      type: "professional",
      // is_accident: values?.is_accident,
      subtype: "op",
      offline_verification_id: null,
      offline_verification_date: null,
      verification_resp_ident_value: null,
      verification_resp_ident_url: null,
      physicians_id: [detail?.data?.id],
      diagnosis: updateddiagnosis,
      supporting_info: convertClinicalData(values?.supportingInfos),
      is_accident: false,
      accident_info: {
        street_name: "",
        city: "",
        state: "",
        country: "",
        date: "",
      },
      items: UpdatedItems,
      vision_prescription: null,
      is_vision_prescription: false,
      encounter: null,
    };

    requestPreAuth({
      variables: {
        input: mappedValues,
      },
    })

    // error show
    // .then((response) => {
    //   const { data } = response;
    //   if (data?.preAuthRequest?.nphies_status === "error") {
    //     const errorMessage = data?.preAuthRequest?.nphies_err
    //       ?.map((err) => `${err.display} (${err.code})`)
    //       .join(", ");
    //     toast.error(`Error: ${errorMessage}`);
    //   } else {
    //     // handle success
    //   }
    // })
    // .catch((error) => {
    //   toast.error("An unexpected error occurred. Please try again.");
    //   console.error(error);
    // });

  };
  // console.log(formikRef?.current?.values);
  // console.log(formikRef?.current?.errors);
  const diagnosisTypes = [
    { id: "principal", name: "Principal Diagnosis" },
    { id: "secondary", name: "Secondary Diagnosis" },
    { id: "admitting", name: "Admitting Diagnosis" },
    { id: "discharge", name: "Discharge Diagnosis" },
    { id: "differential", name: "Differential Diagnosis" },
  ];

  const [checkboxes, setCheckboxes] = useState({
    er_case: false,
    chronic: false,
    congential: false,
    rta: false,
    work_related: false,
    vaccination: false,
    checkup: false,
    psychiatric: false,
    infertility: false,
    pregnancy: false,
    lactation: false,
    acute: false,
  });

  // populate initial values from emr when component mounts
  useEffect(() => {
    if (emr) {
      setCheckboxes({
        er_case: emr.er_case || false,
        chronic: emr.chronic || false,
        congential: emr.congential || false,
        rta: emr.rta || false,
        work_related: emr.work_related || false,
        vaccination: emr.vaccination || false,
        checkup: emr.checkup || false,
        psychiatric: emr.psychiatric || false,
        infertility: emr.infertility || false,
        pregnancy: emr.pregnancy || false,
        lactation: emr.lactation || false,
        acute: emr.acute || false,
      });
    }
  }, [emr]);

  // Handle checkbox changes and state handling
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckboxes((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const hasInsurancePlans =
    (emr && emr.patient?.insurance_plans?.length > 0) ||
    patient?.patient?.insurance_plans?.length > 0;

  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  return (
    <Formik
      initialValues={formInitialValues}
      enableReinitialize={true}
      validate={(values) => {
        const errors = {};
        return errors;
      }}
      innerRef={formikRef}
      
      onSubmit={(values, { setSubmitting }) => {
        if (!patient?.patient?.patient_id) {
          toast.error("Please select a patient");
          return;
        }
        const updatedVital = values?.vitalSigns.map((vt) => ({
          category: vt?.category,
          startDate: vt?.startDate,
          endDate: vt?.endDate,
          value: String(vt?.value),
          valueUnit: vt?.valueUnit,
        }));
        CreateNewEmr(setLoading, {
          ...values,
          patientID: patient?.patient?.patient_id,
          payerId: values?.payerId,
          dateOrdered: values?.dateOrdered,
          claimType: values?.claimType,
          careTeamsArr: values?.careTeamsArr,
          diagnosisArr: values?.diagnosisArr.map((item) => ({
            ...item,
            on_admission: String(item.on_admission),
          })),
          visit_id: parseInt(values?.visit_id, 10),
          supportingInfos: [...values?.supportingInfos, ...updatedVital],
          items: values?.items.map((item) => ({
            ...item,
            net: parseFloat(item.net),
            patient_share: parseFloat(item.patient_share),
            payer_share: String(item.payer_share),
            is_package: String(item.is_package),
          })),
          encounterStatus: values?.encounterStatus,
          immunization: values?.immunization,
          isImmunization: values?.isImmunization,
          allergyIntolerance: values?.allergyIntolerance,
        }).then(() => {
          navigate("/his/patient");
        });
        setSubmitting(false);
      }}
      validationSchema={preAuthValidationSchema}
    >
      {({
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        values,
        errors,
        touched,
      }) => (
        <>
          <div className="pre_auth_req_page">
            <div className="pre_auth_details_top !p-3 !mb-3 flex w-full items-center flex-row">
              <div className="flex w-[28%] gap-3 items-center">
                <div
                  className="back"
                  onClick={() => {
                    navigate("/his/patient");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9 19L10.41 17.59L5.83 13H22V11H5.83L10.42 6.41L9 5L2 12L9 19Z"
                      fill="#4D4D53"
                    />
                  </svg>
                </div>
                <div>
                  <div className="pre_auth_details_title">
                    <span>Electronic Medical Record</span>
                  </div>
                  <div className="pre_auth_details_content">
                    <span>EMR Form</span>
                  </div>
                </div>
              </div>
              <div className="w-[70%]">
                <div className="flex flex-col w-full gap-3 mt-1.5">

                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Full Name :
                      </span>
                      <span>
                        {emr?.patient?.full_name ||
                          patient?.patient?.full_name ||
                          "N/A"}
                      </span>
                    </div>
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Document ID :
                      </span>
                      <span>
                        {emr?.patient?.document_id ||
                          patient?.patient?.document_id ||
                          "N/A"}
                      </span>
                    </div>
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        File No :
                      </span>
                      <span>
                        {emr?.patient?.file_no ||
                          patient?.patient?.file_no ||
                          "N/A"}
                      </span>
                    </div>

                    {/* <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Insurance Plan :
                      </span>
                      <span>
                        {emr ? emr?.patient?.insurance_plans : patient?.patient?.insurance_plans?.length > 0
                          ? emr ? emr?.patient?.insurance_plans : patient?.patient?.insurance_plans.map(
                              (plan, index) => (
                                <span key={index}>
                                  {plan.plan_name || "Unnamed Plan"}
                                  {index <
                                  emr ? emr?.patient?.insurance_plans.length - 1 : patient?.patient?.insurance_plans.length - 1
                                    ? ", "
                                    : ""}
                                </span>
                              )
                            )
                          : "N/A"}
                      </span>
                    </div> */}

                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Date Of Birth :
                      </span>
                      <span>
                        {emr?.patient?.dob || patient?.patient?.dob || "N/A"}
                      </span>
                    </div>

                    {/* <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Insurance Plan:
                      </span>
                      <span>
                        {(() => {
                          const insurancePlans =
                            emr?.patient?.insurance_plans ||
                            patient?.patient?.insurance_plans;
                          if (!insurancePlans || insurancePlans.length === 0) {
                            return "N/A";
                          }
                          return insurancePlans.map((plan, index) => (
                            <span key={index}>
                              {plan.plan_name || "Unnamed Plan"}
                              {index < insurancePlans.length - 1 ? ", " : ""}
                            </span>
                          ));
                        })()}
                      </span>
                    </div> */}

                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        City :
                      </span>
                      <span>
                        {emr?.patient?.city || patient?.patient?.city || "N/A"}
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-row items-center gap-x-3">
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Policy Class:
                      </span>
                      <span>
                        {emr?.patient?.insurance_plans[0]?.policy_class ||
                          patient?.patient?.insurance_plans[0]?.policy_class ||
                          "N/A"}
                      </span>
                    </div>

                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Policy Holder:
                      </span>
                      <span>
                        {emr?.patient?.insurance_plans[0]?.policy_holder ||
                          patient?.patient?.insurance_plans[0]?.policy_holder ||
                          "N/A"}
                      </span>
                    </div>

                    {/* <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Doctor Name :
                      </span>
                      {loggedUser?.FullName}
                      <span> {detail?.data?.name || "N/A"}</span>
                    </div>
                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Doctor Speciality :
                      </span>
                      {loggedUser?.RoleName}
                      <span> {detail?.data?.speciality || "N/A"}</span>
                    </div> */}
                  </div>

                  <div className="flex flex-row  justify-between items-center  ">
                  <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Payer:
                      </span>
                      <span>
                        {emr?.patient?.insurance_plans[0]?.payer_name ||
                          patient?.patient?.insurance_plans[0]?.payer_name ||
                          "N/A"}
                      </span>
                    </div>

                    <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Doctor Name :
                      </span>
                      {loggedUser?.FullName}
                      {/* <span> {detail?.data?.name || "N/A"}</span> */}
                    </div>
                    {/* <div className="flex flex-row">
                      <span className="text-gray-600 font-semibold">
                        Doctor Speciality :
                      </span>
                      {loggedUser?.RoleName}
                      <span> {detail?.data?.speciality || "N/A"}</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="!grid !grid-cols-1 new">
              <div className=" shadow-md !w-full !p-3 rounded-lg border border-gray-400 !mb-3 !bg-gray-100">
                <div className="grid grid-cols-2 gap-2 !mb-4 history ">
                  <div className="!w-full">
                    <label className="text-sm">Past History</label>
                    <Field
                      as="textarea"
                      name="past_history"
                      placeholder="Enter Value"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      type="text"
                      value={values.past_history}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="past_history"
                      rows={1}
                    />
                  </div>

                  <div className="!w-full">
                    <label className="text-sm">Family History</label>

                    <Field
                      as="textarea"
                      placeholder="Enter Value"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      name="family_history"
                      // onChange={historyHhandleChange}
                      // value={emr?.family_history || ""}
                      type="text"
                      value={values.family_history}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="family_history"
                      rows={1}
                    />
                  </div>
                </div>

                <div className="!mb-3 checkbox">
                  <FormGroup className="!grid !grid-cols-4 md:!grid-cols-6">
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="er_case"
                          checked={values.er_case}
                        />
                      }
                      label="ER Case?"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="chronic"
                          checked={values.chronic}
                        />
                      }
                      label="Chronic"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="congential"
                          checked={values.congential}
                        />
                      }
                      label="Congenital"
                    />
                    <FormControlLabel
                      control={
                        <Field as={Checkbox} name="rta" checked={values.rta} />
                      }
                      label="RTA"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="work_related"
                          checked={values.work_related}
                        />
                      }
                      label="Work Related"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="vaccination"
                          checked={values.vaccination}
                        />
                      }
                      label="Vaccination"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="checkup"
                          checked={values.checkup}
                        />
                      }
                      label="Checkup"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="psychiatric"
                          checked={values.psychiatric}
                        />
                      }
                      label="Psychiatric"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="infertility"
                          checked={values.infertility}
                        />
                      }
                      label="Infertility"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="pregnancy"
                          checked={values.pregnancy}
                        />
                      }
                      label="Pregnancy"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="lactation"
                          checked={values.lactation}
                        />
                      }
                      label="Lactation"
                    />
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          name="acute"
                          checked={values.acute}
                        />
                      }
                      label="Acute"
                    />
                  </FormGroup>
                </div>

                <div className="fieldAddFields">
                  <div className="Diagnose first">
                    <FieldArray name="diagnosisArr">
                      {({ push, remove }) => {
                        return (
                          <>
                            {/* <div class="care_teams !p-3 !mb-3" id="careTeams2"> */}
                            <div class="">
                              <div class="flex_cont !mb-3">
                                <div className="w-full ">
                                  <label className="text-sm !mb-1">
                                    Diagnose
                                  </label>
                                  <DiagnosisSearchNSelect
                                    setFieldValue={(val) => {
                                      const newIndex =
                                        values.diagnosisArr.length;
                                      push({
                                        id: newIndex + 1,
                                        codeDescription: val.AsciiDescription,
                                        type:
                                          newIndex === 0
                                            ? "principal"
                                            : newIndex === 1
                                            ? "secondary"
                                            : newIndex === 2
                                            ? "admitting"
                                            : newIndex === 3
                                            ? "discharge"
                                            : newIndex === 4
                                            ? "differential"
                                            : "",
                                        on_admission: false,
                                        codeId: val.CodeId,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div class="pre_auth_communication_table">
                                <div className="">
                                  <div id="tableBody">
                                    {values?.diagnosisArr?.map(
                                      (item, index) => (
                                        <div
                                          key={`diagnosis-${index}`}
                                          className="personal-data !p-3 !mb-3 last:!mb-0"
                                        >
                                          <div className="personal-fields grid grid-cols-2 md:grid-cols-4 gap-2">
                                            <div className="">
                                              <label className="text-sm">
                                                Code-Description
                                              </label>
                                              <Tooltip
                                                title={
                                                  values.diagnosisArr[index]
                                                    ?.codeDescription
                                                    ? `${values.diagnosisArr[index]?.codeDescription} - ${values.diagnosisArr[index]?.codeId}`
                                                    : ""
                                                }
                                              >
                                                <span>
                                                  (
                                                  {values.diagnosisArr[index]
                                                    ?.codeDescription
                                                    ? `${values.diagnosisArr[
                                                        index
                                                      ]?.codeDescription.slice(
                                                        0,
                                                        20
                                                      )}...`
                                                    : ""}
                                                  )
                                                </span>
                                              </Tooltip>
                                              <DiagnosisSearchNSelect
                                                setFieldValue={(val) => {
                                                  setFieldValue(
                                                    `diagnosisArr.${index}.codeDescription`,
                                                    val.AsciiDescription
                                                  );
                                                  setFieldValue(
                                                    `diagnosisArr.${index}.codeId`,
                                                    val.CodeId
                                                  );
                                                }}
                                              />
                                              <ErrorMessage
                                                name={`diagnosisArr.${index}.codeDescription`}
                                                component="div"
                                                className="text-red-500 text-xs"
                                              />
                                            </div>
                                            <div className="flex items-end">
                                              <div className="w-full">
                                                <label className="text-sm">
                                                  Type
                                                </label>
                                                <Field
                                                  as="select"
                                                  name={`diagnosisArr.${index}.type`}
                                                  value={
                                                    index === 0
                                                      ? "principal"
                                                      : index === 1
                                                      ? "secondary"
                                                      : index === 2
                                                      ? "admitting"
                                                      : index === 3
                                                      ? "discharge"
                                                      : index === 4
                                                      ? "differential"
                                                      : ""
                                                  }
                                                >
                                                  <option value=""></option>
                                                  {diagnosisTypes.map(
                                                    (item) => (
                                                      <option
                                                        key={item.id}
                                                        value={item.id}
                                                      >
                                                        {item.name}
                                                      </option>
                                                    )
                                                  )}
                                                </Field>
                                                <ErrorMessage
                                                  name={`diagnosisArr.${index}.type`}
                                                  component="div"
                                                  className="text-red-500 text-xs"
                                                />
                                              </div>
                                            </div>
                                            <div className="">
                                              <label className="text-sm">
                                                On Admission
                                              </label>
                                              <Field
                                                as="select"
                                                name={`diagnosisArr.${index}.on_admission`}
                                              >
                                                <option value="">
                                                  On Admission
                                                </option>
                                                <option value={true}>
                                                  Yes
                                                </option>
                                                <option value={false}>
                                                  No
                                                </option>
                                              </Field>
                                              <ErrorMessage
                                                name={`diagnosisArr.${index}.on_admission`}
                                                component="div"
                                                className="text-red-500 text-xs"
                                              />
                                            </div>
                                            <div className="flex justify-end items-end">
                                              <div
                                                className="remove-row"
                                                onClick={() => remove(index)}
                                              >
                                                <svg
                                                  width="38"
                                                  height="38"
                                                  viewBox="0 0 38 38"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <circle
                                                    cx="19"
                                                    cy="19"
                                                    r="19"
                                                    fill="#DE5042"
                                                  />
                                                  <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z"
                                                    fill="white"
                                                  />
                                                  <mask
                                                    id="mask0_235_11306"
                                                    style={{
                                                      WebkitMaskType:
                                                        "luminance",
                                                      maskType: "luminance",
                                                    }}
                                                    maskUnits="userSpaceOnUse"
                                                    x="11"
                                                    y="18"
                                                    width="16"
                                                    height="2"
                                                  >
                                                    <path
                                                      fill-rule="evenodd"
                                                      clip-rule="evenodd"
                                                      d="M26 20H12C11.448 20 11 19.553 11 19C11 18.447 11.448 18 12 18H26C26.553 18 27 18.447 27 19C27 19.553 26.553 20 26 20Z"
                                                      fill="white"
                                                    />
                                                  </mask>
                                                  <g mask="url(#mask0_235_11306)"></g>
                                                </svg>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              {errors.diagnosisArr &&
                              touched.diagnosisArr &&
                              typeof errors.diagnosisArr === "string" ? (
                                <div className="max-w-2xl mt-2 text-sm leading-6">
                                  <p className="text-red-500">
                                    {errors.diagnosisArr}
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                      }}
                    </FieldArray>
                  </div>
                </div>
              </div>
            </div>

            {/* don't Remove */}
            {/* <div className="!grid !grid-cols-1 "> */}
            {/* <CareTeam data={detail} /> */}
            {/* <Diagnosis /> */}
            {/* <AllergyIntolerance /> */}
            {/* </div> */}

            <div className="!grid !grid-cols-1 ">
              <AllergyIntolerance />
            </div>

            <EmrSupportingINfo />
            <SupportingInfoVitalSigns />
            <ItemsList emr={true} patient={patient?.patient} />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
              <IsAccident />

              <EncounterForm />
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="shadow-md !p-3 rounded-lg border border-gray-400 !mb-3 !bg-gray-100">
                <div class="flex gap-2 !mb-3">
                  <div class="inventory-svg">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="34" height="34" rx="8" fill="#F4F6F8"></rect>
                      <path
                        d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
                        fill="#F5793B"
                      ></path>
                      <path
                        d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
                        fill="#F5793B"
                      ></path>
                    </svg>
                  </div>
                  <div class="personal-head ">
                    <span className="!text-[#344054] text-lg font-semibold">
                      Immunization
                    </span>
                    <div className="flex space-x-3 md:mt-0 md:ml-4 mr-2">
                      <Switch
                        checked={values.isImmunization}
                        onChange={() =>
                          setFieldValue(
                            "isImmunization",
                            !values.isImmunization
                          )
                        }
                        className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-amber-600 focus:ring-offset-2"
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                        />
                        <span
                          aria-hidden="true"
                          className={classNames(
                            values.isImmunization
                              ? "bg-amber-600"
                              : "bg-gray-200",
                            "pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
                          )}
                        />
                        <span
                          aria-hidden="true"
                          className={classNames(
                            values.isImmunization
                              ? "translate-x-5"
                              : "translate-x-0",
                            "pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>
                {values?.isImmunization && (
                  <div className="grid grid-cols-2 gap-3">
                    <input
                      className="w-full rounded-md border-none"
                      type="datetime-local"
                      name="immunization.occurrenceDateTime"
                      value={values.immunization.occurrenceDateTime}
                      onChange={handleChange}
                      id="date"
                      placeholder="Date"
                    />
                    <input
                      className="w-full rounded-md border-none"
                      name="immunization.vaccine_code"
                      value={values.immunization.vaccine_code}
                      onChange={handleChange}
                      id="vaccine_code"
                      placeholder="Vaccine Code"
                    />
                    <select
                      className="w-full rounded-md border-none"
                      name="immunization.status"
                      onChange={handleChange}
                      value={values.immunization.status}
                      id="status"
                    >
                      <option value="">Status</option>
                      {statusValues.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.value}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div className="shadow-md !p-3 rounded-lg border border-gray-400 !mb-3 !bg-gray-100">
                <div class="flex gap-2 !mb-2">
                  <div class="inventory-svg">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="34" height="34" rx="8" fill="#F4F6F8"></rect>
                      <path
                        d="M9.25 11H11.25V14H21.25V11H23.25V16H25.25V11C25.25 9.9 24.35 9 23.25 9H19.07C18.65 7.84 17.55 7 16.25 7C14.95 7 13.85 7.84 13.43 9H9.25C8.15 9 7.25 9.9 7.25 11V25C7.25 26.1 8.15 27 9.25 27H15.25V25H9.25V11ZM16.25 9C16.8 9 17.25 9.45 17.25 10C17.25 10.55 16.8 11 16.25 11C15.7 11 15.25 10.55 15.25 10C15.25 9.45 15.7 9 16.25 9Z"
                        fill="#F5793B"
                      ></path>
                      <path
                        d="M25.25 17.5L19.76 23L16.75 20L15.25 21.5L19.76 26L26.75 19L25.25 17.5Z"
                        fill="#F5793B"
                      ></path>
                    </svg>
                  </div>
                  <div class="personal-head ">
                    <span className="!text-[#344054] text-lg font-semibold">
                      Doctor Remarks
                    </span>
                  </div>
                </div>
                <div className="">
                  <div className="!w-full">
                    <textarea
                      placeholder="Enter Remarks"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-amber-500 focus:ring-amber-500 sm:text-sm"
                      name="doctor_remarks"
                      type="text"
                      value={values.doctor_remarks}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="doctor_remarks"
                      rows={3}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="flex justify-center gap-2">
                <Button
                  variant="contained"
                  size="small"
                  type="submit"
                  style={{
                    backgroundImage:
                      "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                  }}
                  onClick={handleSubmit}
                  startIcon={<CheckOutlinedIcon />}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>

                {hasInsurancePlans && (
                  <Button
                    variant="contained"
                    size="small"
                    type="button"
                    style={{
                      backgroundImage:
                        "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                    }}
                    className={` disabled:!text-white `}
                    onClick={() => requestPreAuthApi(values)}
                    startIcon={<DoneAllIcon />}
                  >
                    Request Pre Auth and Save Emr
                  </Button>
                )}
                {/* {patient?.patient?.insurance_plans?.length > 0 && (
                  <Button
                    variant="contained"
                    size="small"
                    type="button"
                    style={{
                      backgroundImage:
                        "linear-gradient(83deg, #f14f3e 0%, #fab768 100%)",
                    }}
                    className={` disabled:!text-white `}
                    onClick={() => requestPreAuthApi(values)}
                    startIcon={<DoneAllIcon />}
                  >
                    Request Pre Auth and Save Emr
                  </Button>
                )} */}
              </div>
            </div>
          </div>
          <AppLoader
            setShowLoader={setLoading}
            showLoader={Loading || loading}
          />
          {/* <EmrPreAuthModel
            open={open}
            setOpen={setOpen}
            values={values}
            rcmDetail={rcmDetail}
            Detail={detail}
          /> */}
        </>
      )}
    </Formik>
  );
}

// const initialEmr = location.state?.emr || {
//   past_history: "",
//   family_history: "",
//   doctor_remarks: "",
// };
// const [emr, setEmr] = useState(initialEmr);

// const historyHhandleChange = (e) => {
//   const { name, value } = e.target;
//   setEmr((prevEmr) => ({
//     ...prevEmr,
//     [name]: value,
//   }));
// };

// useEffect(() => {
//   setEmr(location.state?.emr || { past_history: "", family_history: "", doctor_remarks: "" });
// }, [location.state]);
