import React, { useContext, useEffect } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  useQuery,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import AppFlow from "../src/navigation/AppFlow";
import ptpa from "../src/gql/ptpa";
import { appContext } from "./context/ContextProvider";

function ApolloAppProvider(props) {
  const { token, setToken } = useContext(appContext);

  // const {
  //   data: payersData,
  //   loading: loadingPayers,
  //   error: errorPayer,
  // } = useQuery(ptpa.GET_ALL_PAYERS, {
  //   fetchPolicy: "network-only", // Optionally ensure you fetch from network
  // });

  // // Save payers data to localStorage on app load
  // useEffect(() => {
  //   if (payersData) {
  //     const payers = payersData.listPayersWithTpa.data;
  //     localStorage.setItem("payersData", JSON.stringify(payers));
  //   }
  // }, [payersData]);
  const httpLink = createHttpLink({
    uri: "https://api.super-git.com/query",
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) =>
        console.log(`[GraphQL error]: Message: ${message}`)
      );
    }
    if (networkError && networkError.statusCode === 403) {
      console.log(":: Forbidden request!");
      localStorage.clear();
      setToken(null);
    }
  });
  // if (loadingPayers) {
  //   return <div>Loading...</div>; // Optionally show loading indicator
  // }

  // if (errorPayer) {
  //   console.error("Error fetching payers:", errorPayer);
  //   return <div>Error loading payers data!</div>; // Optionally show error message
  // }

  const client = new ApolloClient({
    link: errorLink.concat(authLink).concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <AppFlow />
    </ApolloProvider>
  );
}

export default ApolloAppProvider;
